
let wishlistLoadingStatus = ref(false);

export default () => {
  const {fbAddToWishlist} = useFacebookPixel();
  const {gAddToWishlist} = useGoogleEvents();

  const { $event } = useNuxtApp()

  const { $showToast } = useNuxtApp();
  const wishlistsDataIsLoading = useState(
    "wishlistsDataIsLoading",
    () => false
  );
  const wishlistErrors = useState("wishlistErrors", () => {});
  const wishlists = useState("wishlists", () => []);

  const addWishlist = async (selectedVariant, callback) => {
    const { data } = await useFetch("/api/customer/wishlist", {
      method: "POST",
      body: {
        variantId: selectedVariant.variantId,
      },
    });

    if (data.value?.status === "success") {

      // Favoriye eklendi
      if (data.value?.action === 1) {
        fbAddToWishlist(selectedVariant?.title, [selectedVariant?.variantId], selectedVariant?.primaryStockData?.currency, parseFloat(selectedVariant?.primaryStockData?.price))
        gAddToWishlist(selectedVariant?.primaryStockData?.currency, parseFloat(selectedVariant?.primaryStockData?.price), selectedVariant?.variantId, selectedVariant?.title)
      } else { // Sepetten çıkarıldı

      }

      try {
        if (isRef(selectedVariant)) {
          selectedVariant.value.isFavorited = data.value?.action === 1;
        } else {
          selectedVariant.isFavorited = data.value?.action === 1;
        }
      } catch (e) {
        console.log('ürün favoriye eklenirken bir sorun oluştu!', e)
      }

      // $event('addWishlist', {
      //   variantId : selectedVariant.variantId,
      //   status : data.value?.action === 1
      // })

      return data.value?.action === 1;
    }

    return false;
  };

  const getWishlists = async () => {
    wishlistsDataIsLoading.value = true;

    const data = await $fetch("/api/customer/wishlist", {
      server: false,
    });

    // Set Wishlist List
    wishlists.value = data?.response;
    wishlistsDataIsLoading.value = false;
  };

  // Remove Wishlist
  const removeWishlist = async (selectedVariant) => {
    wishlistErrors.value = [];

    const { data, error, refresh } = await useFetch(`/api/customer/wishlist`, {
      method: "post",
      body: {
        variantId: typeof selectedVariant !== 'number' ? selectedVariant.variantId : selectedVariant,
      },
      onResponseError({ request, response, options, error }) {
        // Handle the response errors
        wishlistErrors.value = response._data?.errors;
      },
    });

    if (data.value?.status === "success") {
      $showToast(unref(data).message, unref(data).status);

      if (typeof selectedVariant !== 'number') {
        if (isRef(selectedVariant)) {
          selectedVariant.value.isFavorited = false;
        } else {
          selectedVariant.isFavorited = false;
        }
      }

      getWishlists();
    }
  };

  return {
    addWishlist,
    getWishlists,
    removeWishlist,
    wishlists,
    wishlistErrors,
    wishlistLoadingStatus,
    wishlistsDataIsLoading,
  };
};
